import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { getCaseURL } from "../utils/get-asset-url";

const PortfolioRow = ({ items, caseItem }) => {
	return (
		<>
			{items.map((image, i) => (
				<div key={`${i}${image.id}`} className="group aspect-3/2">
					<Link to={getCaseURL(caseItem.slug)}>
						<GatsbyImage
							className="w-full h-full object-cover group-hover:scale-105 group-hover:z-50 transition-all"
							objectPosition={"50% 30%" ?? "50% 50%"}
							imgClassName="rounded-spark"
							alt={caseItem.title}
							image={getImage(image.directus_files_id.imageFile)}
						/>
					</Link>
				</div>
			))}
		</>
	);
};

export default PortfolioRow;

import React, { Fragment } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Title from "../components/title";
import Layout from "../components/layout";
import PortfolioRow from "../components/portfolio-row";
import Button from "../components/button";
import { getCaseURL } from "../utils/get-asset-url";
import Heading from "../components/heading";
import { Seo } from "../components/seo";

const PortfolioIndexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			directus {
				cases {
					id
					title
					slug
					cover {
						id
						imageFile {
							childImageSharp {
								gatsbyImageData(width: 600, quality: 75)
							}
						}
					}
					images {
						directus_files_id {
							id
							focus
							imageFile {
								childImageSharp {
									gatsbyImageData(width: 600, quality: 75)
								}
							}
						}
					}
				}
			}
		}
	`);

	return (
		<Layout>
			<main className="divide-y divide-off-white">
				<Title>Portfolio</Title>

				<section className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-1 p-1">
					{data.directus.cases.map(function (caseItem, i) {
						return (
							<Fragment key={caseItem.id}>
								<span className="aspect-3/2 w-full h-auto flex items-center justify-center bg-off-white font-bold rounded-spark">
									<div className="text-center">
										<Heading as="h2" size="text-3xl mb-3">
											{caseItem.title}
										</Heading>
										<Button
											to={getCaseURL(caseItem.slug)}
											dark={true}
										>
											Bekijk case
										</Button>
									</div>
								</span>

								<PortfolioRow
									items={caseItem.images}
									caseItem={caseItem}
								/>
							</Fragment>
						);
					})}
				</section>
			</main>
		</Layout>
	);
};

export default PortfolioIndexPage;

export const Head = ({ location }) => (
	<Seo
		pathname={location.pathname}
		pageTitle="Portfolio"
		title="Portfolio - SPARK - fotografie voor cultuur, sport en entertainment"
		description="Bekijk ons uitgebreide fotografie portfolio vol foto\'s voor cultuur, sport en entertainment."
	/>
);
